import { bot_menu_plan, bot_menu_plan_axo, bot_menu_plan_axo_axo, bot_menu_plan_orbit, bot_menu_plan_orbit_axo, bot_menu_plan_orbit_axo_axo, bot_menu_plan_plan, leftMenuBase, leftMenuHouse } from "./menus";

export default {
    // Just plan
    poi_p_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan,
    },

    // tmp need delete
    poi_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan,
    },


    // Just plan duplex
    poi_pp_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_plan,
    },

    poi_pp_plan_2: {
        image: "%plan_2%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_plan,
    },

    // Plan Axo
    poi_pa_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo,
    },
    poi_pa_axo: {
        image: "%axo%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo,
    },

    // Plan Plan Axo Axo
    poi_ppaa_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo,
    },
    poi_ppaa_plan_2: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo,
    },
    poi_ppaa_axo: {
        image: "%axo%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo,
    },
    poi_ppaa_axo_2: {
        image: "%axo%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo,
    },

    // Plan axo axo
    poi_paa_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo_axo,
    },
    poi_paa_axo: {
        image: "%axo%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo_axo,
    },
    poi_paa_axo_r1: {
        image: "%axo_r1%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_axo_axo,
    },

    // Plan orbit 
    p_po_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_orbit,
    },
    p_po_orbit: {
        orbit: "%orbit%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_orbit,
        leftMenu: leftMenuHouse,
        compas: true,
    },

    // Plan orbit axo
    p_poa_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_orbit_axo,
    },
    p_poa_axo: {
        image: "%axo%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_orbit_axo,
    },
    p_poa_orbit: {
        orbit: "%orbit%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_orbit_axo,
        leftMenu: leftMenuHouse,
        compas: true,
    },

    // Plan orbit axo axo
    p_poaa_plan: {
        image: "%plan%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_orbit_axo_axo,
    },
    p_poaa_axo: {
        image: "%axo%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_orbit_axo_axo,
    },
    p_poaa_axo_r1: {
        image: "%axo_r1%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_orbit_axo_axo,
    },
    p_poaa_orbit: {
        orbit: "%orbit%",
        back: "scene",
        infoCard: "%id%",
        botMenu: bot_menu_plan_orbit_axo_axo,
        leftMenu: leftMenuHouse,
        compas: true,
    },

    // Sub orbital
    base_lot: {
        orbit: "%orbit%",
        compas: true,
        back: "scene",
        searchButton: true,
        typeFilter: true,
    },

    base: {
        orbit: "%orbit%",
        compas: true,
        leftMenu: leftMenuBase,
        searchButton: true,
        typeFilter: true,
    },

    search: {
        back: "scene",
        backColor: "FFFFFF",
        search: true,
    },

    favorites: {
        back: "scene",
        favorites: true,
    }
}